
import { Vue } from "vue-class-component";
import WaitlistRestService from "@/api/WaitlistRestService";

export default class Join extends Vue {
	email = "";

	errorMessage = "";

	loading = false;

	async submit(): Promise<void> {
		if (!this.isValidEmail(this.email)) {
			// The email entered is invalid
			this.errorMessage = "Please enter a valid email";
			return;
		} else {
			// The email entered is valid
			this.errorMessage = "";

			this.loading = true;

			try {
				await WaitlistRestService.create(this.email);
				this.$router.push("/joined");
			} catch (error) {
				// TODO: Handel error more appropriately
				this.errorMessage = "An error occurred";
			}

			this.loading = false;
		}
	}

	// TODO: This should be abstracted to a utility class
	isValidEmail(email: string): boolean {
		return !!email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}
}
