
import HelpRestService from "@/api/HelpRestService";
import { Vue } from "vue-class-component";

export default class Help extends Vue {
	email = "";

	emailError = "";

	inquiry = "";

	inquiryError = "";

	loading = false;

	async submit(pointerEvent: MouseEvent): Promise<void> {
		pointerEvent.preventDefault();

		let error = false;

		if (!this.isValidEmail(this.email)) {
			// The email entered is invalid
			this.emailError = "Please enter a valid email";
			error = true;
		} else {
			this.emailError = "";
		}

		if (this.isFalseyOrWhiteSpace(this.inquiry)) {
			this.inquiryError = "Please specify a reason for your inquiry";
			error = true;
		} else {
			this.inquiryError = "";
		}

		if (!error) {
			this.loading = true;

			try {
				await HelpRestService.create(this.email, null, this.inquiry);
				this.$router.push("/helped");
			} catch (error) {
				// TODO: Handel error more appropriately
				this.emailError = "An error occurred";
				this.inquiryError = "An error occurred";
			}

			this.loading = false;
		}
	}

	isFalseyOrWhiteSpace(value: string | null | undefined): boolean {
		return !value || /^\s*$/.test(value);
	}

	// TODO: This should be abstracted to a utility class
	isValidEmail(email: string): boolean {
		return !!email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}
}
