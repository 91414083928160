
import { Options, Vue } from "vue-class-component";
import ArticleMap from "./Components/ArticleMap/ArticleMap.vue";
import Footer from "@/components/Footer.vue";
import MarkdownRenderer from "@/components/MarkdownRenderer.vue";
import * as MarkdownUtil from "@/util/MarkdownUtil";
import AboutTheAuthor from "./Components/AboutTheAuthor.vue";
import Section from "@/types/Section";

@Options({
	components: {
		ArticleMap,
		Footer,
		MarkdownRenderer,
		AboutTheAuthor
	}
})
export default class Article extends Vue {
	// The markdown for this article
	markdown: string | null = null;

	// The sections for this article. Markdown should be in the expected markdown format
	sections: Array<Section> | null = null;

	// The internal active section
	_activeSection = "";

	// Get the active section ID
	get activeSectionId(): string {
		return this._activeSection;
	}

	// Set the active section ID
	set activeSectionId(sectionId: string) {
		this._activeSection = sectionId;

		this.$router.replace(sectionId ? `#${sectionId}` : "");
	}

	async mounted(): Promise<void> {
		// Get the article ID from the route
		const articleId = this.$route.params.id;

		// Load the article markdown
		let article;
		try {
			article = await import(`!!raw-loader!@/assets/articles/${articleId}.md`);
		} catch (exception) {
			// TODO: Handle exception
			console.log(exception);
			return;
		}

		// Set the markdown
		this.markdown = article.default;

		// Set the sections
		this.sections = MarkdownUtil.getHeadingSections(
			this.markdown!
		)?.[0].subsections;
	}

	// Scroll a section into view by it's section ID
	scrollSectionIntoView(sectionId: string): void {
		// Set the active section
		this.activeSectionId = sectionId;

		// Get the section heading element
		const heading = document.getElementById(sectionId) as HTMLElement;

		// Get the first scrollable parent of the heading
		const scrollableParent = this.getFirstYScrollableParent(heading, false);

		// Scroll to the heading from the scrollable parent and give the heading an offset from the top of the page
		scrollableParent.scrollTo({
			behavior: "smooth",
			top: heading.offsetTop - scrollableParent.offsetTop - 20
		});

		// TODO: Wait for the scrolling to finish before adding the below class
		heading?.classList.add("highlight");

		// After a delay, remove the transition class
		setTimeout(() => {
			heading?.classList.remove("highlight");
		}, 2000);
	}

	// Event handler for when the markdown is finished rendering
	markdownLoaded(): void {
		// Check if the URL has a fragment/hash
		const urlHash = this.$route.hash?.substring(1);

		// If it does, scroll the relevant section into view
		if (urlHash) {
			this.scrollSectionIntoView(urlHash);
		}

		// Initiate the active section detection
		this.initiateActiveSectionDetection();
	}

	// Initiates the active section detection
	initiateActiveSectionDetection(): void {
		// Get the markdown element
		const markdownElement = this.$refs.markdownElement as HTMLElement;

		// Get the first scrollable parent
		const scrollableParent = this.getFirstYScrollableParent(
			markdownElement,
			false
		);

		// Get all the h2 headings from the markdown element
		/**
		 * Note: We're assuming a specific markdown structure here. That is the following structure:
		 * 1. One single h1 at the beginning of the markdown text. This is assumed to be the article title.
		 * 2. All h2 markdown headings are the top-level article sections. These are the only sections that will
		 *    appear on the article sidebar map. Additionally these are the only perma-linkable sections. These
		 *    sections, when scrolled to, will automatically be added as the URL hash. Ex: https://.../article#creating-a-git-account
		 * 3. All other headings (h3,h4,h5,h6) are further subsections just used for organization. They will not
		 *    appear on the sidebar article map, will not be perma-linkable, and will not be added to the URL hash.
		 *    Additionally, as a style guide, smaller subsections should progressively be avoided as it adds noise.
		 *    H2 (the top-level sections), and h3 (direct subsections of h2) will be fairly common while h6 should
		 *    basically never be present.
		 **/
		const headings = markdownElement.querySelectorAll("h2");

		// When the scrollable parent is scrolled, update the active section as necessary
		// TODO: When adding the onscroll event listener here, do we need to remove it when the component is destroyed?
		scrollableParent.onscroll = () => {
			// Iterate over each heading in reverse order. We iterate in reverse order so that we break as soon as
			// we find the currently active heading. If we don't, the first heading will be active every time.
			for (const heading of Array.from(headings).reverse()) {
				if (
					// Scrolltop is the current "scroll position" of the scrollable parent, starting from 0
					// Offset top is the distance in pixels from the nearest positioned ancestor, in this case
					// the app root.
					scrollableParent.scrollTop >=
					heading.offsetTop - scrollableParent.offsetTop - 20
				) {
					// Get the title ID
					const titleId = MarkdownUtil.getTitleId(heading.innerText);

					// Set the active section
					this.activeSectionId = titleId;

					// Return early as not to overwrite the active section
					return;
				}
			}

			// If no section is active, set the value to the empty string
			this.activeSectionId = "";
		};
	}

	// Get the first scrollable parent on the y-axis (height).
	getFirstYScrollableParent(
		element: HTMLElement,
		includeHidden: boolean
	): HTMLElement {
		let style = getComputedStyle(element);
		const excludeStaticParent = style.position === "absolute";
		const overflowRegex = includeHidden
			? /(auto|scroll|overlay|hidden)/
			: /(auto|scroll|overlay)/;

		if (style.position === "fixed") return document.body;
		for (
			let parent: HTMLElement | null = element;
			(parent = parent.parentElement);

		) {
			style = getComputedStyle(parent);
			if (excludeStaticParent && style.position === "static") {
				continue;
			}
			if (overflowRegex.test(style.overflowY)) return parent;
		}

		return document.body;
	}
}
