
import { Vue } from "vue-class-component";

export default class TopBar extends Vue {
	signIn(): void {
		this.$googleApi.signIn().then((token: string) => {
			console.log(token);
		});
	}
}
