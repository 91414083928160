import Section from "@/types/Section";

/**
 * Get the sections from a markdown document.
 *
 * @export
 * @param {string} markdown The markdown document.
 * @returns {Array<Section>} An array of the root markdown sections.
 */
export function getHeadingSections(markdown: string): Array<Section> {
	// Get all the heading lines
	const headingLines = markdown.matchAll(/^(#+)\s+(.+)$/gm);

	// Create a stack of sections to populate
	const sectionsStack = new Array<Section>();

	// Create an array to store the root sections
	const rootSections = new Array<Section>();

	// Iterate over the heading lines while building up a tree
	for (const headingLine of headingLines) {
		// A match is an array containing 3 items.
		// 0: The whole matched line. Ex: `# This is a heading`, `### A smaller heading`
		// 1: The preceding hashes. Ex: `#`, `###`
		// 2: The heading title. Ex: `This is a heading`, `A smaller heading`

		// Get the heading depth
		const headingDepth = headingLine[1].length - 1;

		// Get the heading title
		const headingTitle = headingLine[2];

		// Create this section
		const currentSection = new Section(headingTitle, headingDepth);

		// Check if this is the first heading
		if (sectionsStack.length == 0) {
			// Push this section onto the stack
			sectionsStack.push(currentSection);

			// Add this section to the root level sections
			rootSections.push(currentSection);

			continue;
		}

		// Get the last section
		const lastSection = sectionsStack.at(-1) as Section;

		// Check if this is a child of the last section
		if (currentSection.depth > lastSection.depth) {
			// Add this section to the subsections of the last section
			lastSection.subsections.push(currentSection);

			// Push this section onto the stack
			sectionsStack.push(currentSection);

			continue;
		}

		// This section is not a child of the last section

		// Pop of sections until we reach a shallower section or the sections are empty
		while (sectionsStack.length > 0) {
			// Pop off the previous section
			sectionsStack.pop();

			// Check that there are still sections on the stack
			if (sectionsStack.length == 0) {
				// Push this section onto the stack
				sectionsStack.push(currentSection);

				// Add this section to the root level sections
				rootSections.push(currentSection);

				break;
			}

			// Get the next parent
			const nextParent = sectionsStack.at(-1) as Section;

			if (currentSection.depth > nextParent.depth) {
				// Add this section to the subsections of the last section
				nextParent.subsections.push(currentSection);

				// Push this section onto the stack
				sectionsStack.push(currentSection);

				break;
			}
		}
	}

	return rootSections;
}

/**
 * Get a title ID from title text.
 *
 * @export
 * @param {string} titleText The title text.
 * @returns {string} The title ID.
 */
export function getTitleId(titleText: string): string {
	return titleText
		.toLowerCase()
		.replaceAll(" ", "-")
		.replace(/[^0-9a-z-]/gi, "");
}
