<template>
	<div class="add-block-button">
		<span class="icon">H</span>
		Heading
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../editor-styles.scss";
</style>
