import ICodeHighlighter from "./ICodeHighlighter";
import * as shiki from "shiki";

export default class CodeHighlighter implements ICodeHighlighter {
	highlighter: shiki.Highlighter | null = null;

	ready: Promise<void>;

	constructor(shikiCdn: string) {
		// Verify the Shiki CDN and throw a meaningful error if invalid
		if (!shikiCdn) {
			throw new Error(`Error: Invalid Shiki CND '${shikiCdn}'`);
		}

		// Set the Shiki CDN
		shiki.setCDN(shikiCdn);

		// Set the
		this.ready = shiki
			.getHighlighter({
				theme: "light-plus"
			})
			.then((highlighter): void => {
				this.highlighter = highlighter;
			})
			.catch((error) => {
				console.error(`Error initializing Shiki highlighter: ${error}`);
			});
	}

	async whenReady(): Promise<void> {
		return this.ready;
	}

	async codeToHtmlAsync(language: string, code: string): Promise<string> {
		throw new Error("Not implemented");
	}

	codeToHtml(language: string, code: string): string {
		if (!this.highlighter) {
			throw new Error(
				"Highlighter not initialized. Please wait for 'whenReady' to resolve before calling this method."
			);
		}

		return this.highlighter.codeToHtml(code, language);
	}
}
