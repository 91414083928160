/**
 * Represents a section in a markdown document.
 *
 * @export
 * @class Section
 */
export default class Section {
	/**
	 * The title of the section.
	 *
	 * @type {string}
	 */
	title: string;

	/**
	 * The depth of the section.
	 *
	 * @type {number}
	 */
	depth: number;

	/**
	 * Subsections of this section.
	 *
	 * @type {Array<Section>}
	 */
	subsections: Array<Section>;

	/**
	 * Creates an instance of Section.
	 * @param {string} title The section title.
	 * @param {number} depth The section depth.
	 * @param {Array<Section>} [subsections=new Array<Section>()] The subsections.
	 */
	constructor(
		title: string,
		depth: number,
		subsections: Array<Section> = new Array<Section>()
	) {
		this.title = title;
		this.depth = depth;
		this.subsections = subsections;
	}
}
