
import Section from "@/types/Section";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import ArticleMapSection from "./ArticleMapSection.vue";

@Options({
	components: {
		ArticleMapSection
	}
})
export default class ArticleMap extends Vue {
	@Prop({ required: true })
	sections!: Array<Section>;

	@Prop()
	activeSectionId = "";

	@Emit()
	sectionClicked(sectionId: string): string {
		return sectionId;
	}
}
