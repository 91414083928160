import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/main.scss";
import CodeHighlighter from "./plugins/CodeHighlighter";
import GoogleApiPlugin from "./plugins/GoogleApi";

// Create the vue app
const app = createApp(App);

// Uses vue store
app.use(store);

// Use vue router
app.use(router);

// Use code highlighter
app.use(CodeHighlighter, { shikiCdn: `${process.env.BASE_URL}shiki/` });

// Use Google Apis
app.use(new GoogleApiPlugin(), {
	clientId:
		"693268911343-gu1qucj56dkuv64u9prup6tqpr0k6ejf.apps.googleusercontent.com"
});

// Mount the app
app.mount("#app");
