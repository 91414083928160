import IGoogleApi from "./IGoogleApi";

export default class GoogleApi implements IGoogleApi {
	clientId: string;
	private _ready: Promise<void>;
	private _isReady = false;

	/**
	 * Creates an instance of GoogleApi.
	 *
	 * @param {string} clientId The client ID.
	 */
	constructor(clientId: string) {
		this.clientId = clientId;

		this._ready = this.loadGapiScript()
			.then(() => {
				return this.loadApi("auth2");
			})
			.then(() => {
				// Initialize Google authorization
				gapi.auth2.init({ client_id: clientId });

				this._isReady = true;
			});
	}

	/**
	 * Whether or not the Google API is ready.
	 *
	 * @return {*}  {boolean} Whether or not the Google API is ready.
	 */
	isReady(): boolean {
		return this._isReady;
	}

	/**
	 * A promise which resolves when the Google API is ready.
	 *
	 * @return {*}  {Promise<void>} A promise.
	 */
	whenReady(): Promise<void> {
		return this._ready;
	}

	/**
	 * Sign into a Google account.
	 *
	 * @return {*}  {(Promise<string | void>)} A promise containing the users access token.
	 */
	async signIn(): Promise<string> {
		const googleUser = await gapi.auth2.getAuthInstance().signIn();
		return googleUser.getAuthResponse().id_token;
	}

	/**
	 * Load the Google API script.
	 *
	 * @return {*}  {Promise<void>} A promise resolving when the script is loaded.
	 */
	private loadGapiScript(): Promise<void> {
		return new Promise(function (resolve, reject) {
			const script = document.createElement("script");
			// This may be supported on more browsers
			// script.onload = (script as any).onreadystatechange = () => {
			script.onload = () => {
				resolve();
			};
			script.onerror = reject;
			// script.src = "https://apis.google.com/js/api.js";
			script.src = "https://apis.google.com/js/api:client.js";
			document.getElementsByTagName("head")[0].appendChild(script);
		});
	}

	/**
	 * Load a Google API.
	 *
	 * @param {string} api The API to load.
	 * @return {*}  {Promise<void>} A promise resolving when the API is loaded.
	 */
	private loadApi(api: string): Promise<void> {
		return new Promise((resolve, reject) => {
			gapi.load(api, () => {
				resolve();
			});
		});
	}
}
