
import Section from "@/types/Section";
import { Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import * as MarkdownUtil from "@/util/MarkdownUtil";

export default class ArticleMapSection extends Vue {
	@Prop({ required: true })
	section!: Section;

	@Prop({ default: 0 })
	depth!: number;

	@Prop()
	activeSectionId = "";

	enableSubsections = false;

	@Emit()
	sectionClicked(sectionId: string): string {
		return sectionId;
	}

	getTitleId = MarkdownUtil.getTitleId;
}
