
import { Options, Vue } from "vue-class-component";
import ArticlePreview from "./Components/ArticlePreview.vue";
import ExploreBanner from "./Components/ExploreBanner.vue";
import ExploreSidebar from "./Components/ExploreSidebar.vue";
import CallToAction from "./Components/CallToAction.vue";
import ArticleList from "./Components/ArticleList.vue";
import articles from "@/store/articles";

@Options({
	components: {
		ArticleList,
		ArticlePreview,
		ExploreBanner,
		ExploreSidebar,
		CallToAction
	}
})
export default class Explore extends Vue {
	// A map with article titles as keys and article links (file names) as values
	// articles: Array<{ title: string; link: string; content: string }> = [];
	articles = articles;

	// mounted(): void {
	// 	require
	// 		.context("@/assets/articles", true)
	// 		.keys()
	// 		.forEach(async (articleFile: string) => {
	// 			// Trim the './' from the beginning of the article file name and the '.md' from the end
	// 			const trimmedArticleFile = articleFile.substring(2).replace(/.md$/, "");

	// 			// Load each article
	// 			const article = (
	// 				await import(
	// 					`!!raw-loader!@/assets/articles/${trimmedArticleFile}.md`
	// 				)
	// 			).default;

	// 			// Get the article title
	// 			const articleTitle =
	// 				MarkdownUtil.getHeadingSections(article)?.[0]?.title;

	// 			// Set the article title and link
	// 			this.articles.push({
	// 				title: articleTitle,
	// 				link: trimmedArticleFile,
	// 				content: article
	// 			});
	// 		});
	// }
}
