import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-026aefc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "subsections"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleMapSection = _resolveComponent("ArticleMapSection", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass(["section-title", [
			`depth-${_ctx.section.depth}`,
			_ctx.activeSectionId == _ctx.getTitleId(_ctx.section.title) ? 'active' : ''
		]]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sectionClicked(_ctx.getTitleId(_ctx.section.title))))
    }, _toDisplayString(_ctx.section.title), 3),
    (_ctx.enableSubsections)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.subsections, (subsection) => {
            return (_openBlock(), _createElementBlock("span", { key: subsection }, [
              _createVNode(_component_ArticleMapSection, {
                section: subsection,
                depth: _ctx.depth + 1,
                onSectionClicked: _ctx.sectionClicked
              }, null, 8, ["section", "depth", "onSectionClicked"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}