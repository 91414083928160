
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ArticlePreview from "./ArticlePreview.vue";

@Options({
	components: {
		ArticlePreview
	}
})
export default class ArticleList extends Vue {
	@Prop()
	articles: any;
}
