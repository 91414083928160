import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb62a63a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "explore" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "content-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CallToAction = _resolveComponent("CallToAction")!
  const _component_ArticleList = _resolveComponent("ArticleList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CallToAction, { class: "mb-4" }),
        _createVNode(_component_ArticleList, { articles: _ctx.articles }, null, 8, ["articles"])
      ])
    ])
  ]))
}