import { App } from "@vue/runtime-core";
import GoogleApi from "./GoogleApi";
import IGoogleApi from "./IGoogleApi";

export default class GoogleApiPlugin {
	install(vue: App, options: any): void {
		// TODO: Use a type for the options
		vue.config.globalProperties.$googleApi = new GoogleApi(options.clientId);
	}
}

declare module "@vue/runtime-core" {
	// Bind to `this` keyword
	interface ComponentCustomProperties {
		$googleApi: IGoogleApi;
	}
}
