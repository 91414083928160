import Module from "@/editor/Module";
import HeadingConfig from "./HeadingConfig.vue";
import Heading from "./Heading.vue";
import AddHeading from "./AddHeading.vue";

export default {
	blockConfigComponent: HeadingConfig,
	blockComponent: Heading,
	addBlockComponent: AddHeading
} as Module;
