
import { Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import marked from "marked";
import * as MarkdownUtil from "@/util/MarkdownUtil";

export default class MarkdownRenderer extends Vue {
	@Prop({ required: true })
	markdown!: string;

	@Emit()
	loaded(): void {
		// Do nothing, this will emit the void event when called
	}

	html: string | null = null;

	mounted(): void {
		this.$codeHighlighter.whenReady().then(() => {
			this.compileMarkdown();
		});
	}

	compileMarkdown(): void {
		// Create the markdown renderer
		const render = new marked.Renderer();

		// Set the heading HTML
		render.heading = (text: string, level: 1 | 2 | 3 | 4 | 5 | 6) => {
			const headingId = MarkdownUtil.getTitleId(text);

			switch (level) {
				case 2:
					return `
						<h${level} id="${headingId}">
							<a href="#${headingId}" class="header-anchor">
								<span class="anchor-hash">
									#
								</span>
								<span class="anchor-text">
									${text}
								</span>
							</a>
						</h${level}>
					`;
				default:
					return `<h${level} id="${headingId}">${text}</h${level}>`;
			}
		};

		// Set the image HTML
		render.image = function (
			href: string | null,
			title: string | null,
			text: string
		) {
			return `<img class="marked-image" src="${href}" alt="${text}">`;
		};

		// Set the paragraph HTML
		render.paragraph = function (text: string) {
			return `<p class="marked-paragraph">${text}</p>`;
		};

		// Set the code HTML
		render.code = (code: string, language: string, isEscaped: boolean) => {
			// Highlight the code
			const highlightedCode = this.$codeHighlighter.codeToHtml(language, code);

			// Get a document for the code html
			var parser = new DOMParser();
			var htmlDoc = parser.parseFromString(highlightedCode, "text/html");

			// Get the code element from the document
			const codeElement = htmlDoc.getElementsByTagName("code")[0];

			// Get the inner HTML
			const innerCode = codeElement?.innerHTML || highlightedCode;

			// Set the custom code element
			return `<pre class="marked-code-pre"><code class="marked-code">${innerCode}</code></pre>`;
		};

		render.codespan = (code: string) => {
			return `<code class="inline-code">${code}</code>`;
		};

		// Compile the markdown with the custom renderer
		this.html = marked(this.markdown, {
			renderer: render
		});

		this.$nextTick(() => this.loaded());
	}
}
