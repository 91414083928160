import { App } from "@vue/runtime-core";
import CodeHighlighter from "./CodeHighlighter";
import ICodeHighlighter from "./ICodeHighlighter";

export default {
	install(vue: App, options: any): void {
		// TODO: Use a type for the options
		vue.config.globalProperties.$codeHighlighter = new CodeHighlighter(
			options.shikiCdn
		);
	}
};

declare module "@vue/runtime-core" {
	// Bind to `this` keyword
	interface ComponentCustomProperties {
		$codeHighlighter: ICodeHighlighter;
	}
}
