import Article from "@/types/Article";

const articles: Array<Article> = [
	{
		title: "Creating your first Git repository",
		summary:
			"Step-by-step instructions on creating and using your first git repository",
		thumbnailImageUrl: "https://i.imgur.com/xwkB7sj.jpg",
		// Thumbnail image from https://unsplash.com/photos/wX2L8L-fGeA
		// TODO: Thank the artist
		content: "",
		creationDate: BigInt(637787353840000000n),
		author: "Montana Gau",
		link: "creating-your-first-git-repository"
	}
];

export default articles;
