import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import About from "@/views/About/About.vue";
import Article from "@/views/Article/Article.vue";
import Explore from "@/views/Explore/Explore.vue";
import Join from "@/views/Join/Join.vue";
import Joined from "@/views/Joined.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";
import Help from "@/views/Help/Help.vue";
import Helped from "@/views/Help/Helped.vue";
import Contact from "@/views/Contact/Contact.vue";
import Contacted from "@/views/Contact/Contacted.vue";
import Minecraft from "@/views/Minecraft/Minecraft.vue";
import Editor from "@/views/Editor/Editor.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		// component: Home
		component: About,
		meta: {
			enableHeader: false,
			enableFooter: false,
			enablePageContainer: false
		}
	},
	{
		path: "/article/:id",
		name: "Article",
		component: Article
	},
	{
		path: "/about",
		name: "About",
		meta: {
			enableHeader: false,
			enableFooter: false,
			enablePageContainer: false
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About/About.vue")
	},
	{
		path: "/explore",
		name: "Explore",
		component: Explore
	},
	{
		path: "/join",
		name: "Join",
		component: Join,
		meta: {
			enableFooter: false,
			enableHeader: false
		}
	},
	{
		path: "/joined",
		name: "Joined",
		component: Joined,
		meta: {
			enableFooter: false,
			enableHeader: false
		}
	},
	{
		path: "/terms",
		name: "Terms",
		component: Terms,
		meta: {
			enableFooter: false,
			enableHeader: false
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: Privacy,
		meta: {
			enableFooter: false,
			enableHeader: false
		}
	},
	{
		path: "/help",
		name: "Help",
		component: Help
	},
	{
		path: "/helped",
		name: "Helped",
		component: Helped
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact
	},
	{
		path: "/contacted",
		name: "Contacted",
		component: Contacted
	},
	{
		path: "/minecraft",
		name: "Minecraft",
		component: Minecraft
	},
	{
		path: "/editor",
		name: "Editor",
		component: Editor
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
