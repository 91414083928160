
import { Options, Vue } from "vue-class-component";
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import PageContainer from "@/components/PageContainer.vue";

@Options({
	components: {
		TopBar,
		Footer,
		PageContainer
	}
})
export default class App extends Vue {
	get enableHeader(): boolean {
		return this.$route.meta.enableHeader ?? true;
	}
	get enableFooter(): boolean {
		return this.$route.meta.enableFooter ?? true;
	}
	get enablePageContainer(): boolean {
		return this.$route.meta.enablePageContainer ?? true;
	}
	mounted(): void {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;

		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty("--vh", `${vh}px`);

		// We listen to the resize event
		window.addEventListener("resize", () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		});
	}
}
