import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af7c4712"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleMapSection = _resolveComponent("ArticleMapSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
      return (_openBlock(), _createBlock(_component_ArticleMapSection, {
        key: section,
        section: section,
        "active-section-id": _ctx.activeSectionId,
        onSectionClicked: _ctx.sectionClicked
      }, null, 8, ["section", "active-section-id", "onSectionClicked"]))
    }), 128))
  ]))
}