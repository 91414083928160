
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Article from "@/types/Article";

@Options({
	components: {}
})
export default class ArticlePreview extends Vue {
	@Prop({ required: true })
	article!: Article;

	prettyTime(ticksTime: bigint): string {
		const ticksSinceEpoch = ticksTime - 621355968000000000n;
		const postAgeInMsSinceEpoch = Number(ticksSinceEpoch / 10000n);
		const relativePostAgeInSeconds = Date.now() - postAgeInMsSinceEpoch;

		// In milliseconds
		const units: any = {
			year: 24 * 60 * 60 * 1000 * 365,
			month: (24 * 60 * 60 * 1000 * 365) / 12,
			day: 24 * 60 * 60 * 1000,
			hour: 60 * 60 * 1000,
			minute: 60 * 1000,
			second: 1000
		};

		const relativeTimeFormat = new Intl.RelativeTimeFormat("en", {
			style: "long",
			numeric: "auto"
		});

		for (var unit in units) {
			if (
				Math.abs(relativePostAgeInSeconds) > units[unit] ||
				unit == "second"
			) {
				return relativeTimeFormat.format(
					-Math.round(relativePostAgeInSeconds / units[unit]),
					unit as Intl.RelativeTimeFormatUnit
				);
			}
		}

		return "A while ago";
	}
}
