import axios, { AxiosResponse } from "axios";
import ApiConfig from "./APIConfig";

/**
 * A rest service for interacting with the waitlist.
 *
 * @export
 * @class WaitlistRestService
 */
export default class WaitlistRestService {
	/**
	 * Create an entry in the waitlist.
	 *
	 * @static
	 * @param {string} email The email to create an entry for.
	 * @returns {Promise<AxiosResponse>} A promise containing the response.
	 */
	public static async create(email: string): Promise<AxiosResponse> {
		return axios.post(`${ApiConfig.serverUrl}/waitlist`, null, {
			params: {
				email
			}
		});
	}
}
