
import axios from "axios";
import { Vue } from "vue-class-component";

export default class Minecraft extends Vue {
	disabled = false;

	message = "";

	start() {
		this.disabled = true;

		axios
			.get(
				"https://999ugek1ki.execute-api.us-west-2.amazonaws.com/default/StopEc2Minecraft?on=true"
			)
			.then((response) => {
				this.message = response.data;
				this.disabled = false;
			})
			.catch((error) => {
				this.message = error;
				this.disabled = false;
			});
	}

	stop() {
		this.disabled = true;

		axios
			.get(
				"https://999ugek1ki.execute-api.us-west-2.amazonaws.com/default/StopEc2Minecraft?on=false"
			)
			.then((response) => {
				this.message = response.data;
				this.disabled = false;
			})
			.catch((error) => {
				this.message = error;
				this.disabled = false;
			});
	}
}
