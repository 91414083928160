import axios, { AxiosResponse } from "axios";
import ApiConfig from "./APIConfig";

/**
 * A rest service for interacting with the help API.
 *
 * @export
 * @class HelpRestService
 */
export default class HelpRestService {
	/**
	 * Issue a request for help.
	 *
	 * @static
	 * @param {string} email The email to create an inquiry for.
	 * @param {(string | null)} name The name of the inquirer if available.
	 * @param {string} message The message.
	 * @returns {Promise<AxiosResponse>} A promise containing the response.
	 */
	public static async create(
		email: string,
		name: string | null,
		message: string
	): Promise<AxiosResponse> {
		return axios.post(`${ApiConfig.serverUrl}/help`, {
			email,
			name,
			message
		});
	}
}
